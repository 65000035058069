import { useState, useCallback, useEffect } from "react";

import * as serviceWorkerRegistration from "serviceWorkerRegistration";

export default function usePWAServiceWorker() {
  const [ waitingWorker, setWaitingWorker ] = useState<ServiceWorker | null>(null);
  const [ newUpdateAvailable, setNewUpdateAvailable ] = useState<boolean>(false);

  const onSWUpdate = useCallback((registration: ServiceWorkerRegistration) => {
    setNewUpdateAvailable(true);
    setWaitingWorker(registration.waiting);
  }, []);

  const reloadPage = useCallback(() => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" });
    setNewUpdateAvailable(false);
    window.location.reload();
  }, [waitingWorker]);

  useEffect(() => {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    serviceWorkerRegistration.register({
      onUpdate: onSWUpdate,
    });
  }, [onSWUpdate]);
  
  return { newUpdateAvailable, waitingWorker, reloadPage };
}