import { useMemo } from "react";

import ReactECharts from "echarts-for-react";

import { LoadingSpinner } from "@evos/components";

import type { ApolloError } from "@apollo/client";

type UptimeChartProps = {
  uptimeValue: number
  loading: boolean
  error: ApolloError | undefined
  height?: number
};

export default function UptimeChart({uptimeValue, loading, error, height = 60}: UptimeChartProps) {
  const uptimeChartOptions = useMemo(() => {
    return {
      aria: {
        enabled: true,
        label: {
          description: "Uptime chart",
        }
      },
      series: [
        {
          type: "gauge",
          startAngle: 180,
          endAngle: 0,
          radius: "140%",
          center: ["50%", "85%"],
          min: 0,
          max: 1,
          axisLine: {
            lineStyle: {
              width: height >= 150 ? 40 : 25,
              color: [
                [0.2, "#83CFF2"],
                [0.4, "#93D6F3"],
                [0.6, "#AAE1F6"],
                [0.8, "#C3E9F8"],
                [1, "#BCF7B5"]
              ]
            }
          },
          pointer: {
            icon: "path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z",
            length: "80%",
            width: 8,
            offsetCenter: [0, 0],
            itemStyle: {
              color: "black"
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLabel: {
            show: false
          },
          title: {
            show: false
          },
          detail: {
            show: false
          },
          data: [
            {
              value: uptimeValue,
              name: "Uptime"
            }
          ]
        }
      ]
    };
  }, [height, uptimeValue]);

  return (
    <div id="uptime-chart">
      { (error == null) 
        && <>
          { !loading
            ? <ReactECharts
              option={uptimeChartOptions}
              style={{ height: height, width: "100%" }}
              opts={{renderer: "svg"}}
            />
            : <span className="p-4 d-flex align-items-center"><LoadingSpinner /></span>
          }
        </>
      }
    </div>
  );
}