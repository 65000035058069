import "./loadingSpinner.css";

type LoadingSpinnerProps = {
  variant?: "dark" | "light";
}

const LoadingSpinner = ({variant = "dark"}: LoadingSpinnerProps) => {
  return (
    <div role="status" className={`spinner spinner-variant-${variant}`}>
      <span className="visually-hidden">Loading...</span>
    </div>
  );
};

export default LoadingSpinner;