import { useContext } from "react";
import {
  Routes as RRDRoutes,
  Route
} from "react-router-dom";

import { AuthContext } from "lib/context/auth";

import loadable from "@loadable/component";
import { Loading } from "@evos/components";
import { RedirectSerial, Authorization, RequireAuth, PublicRoute } from "App";

function useAuth() {
  return useContext(AuthContext);
}

type RoutesProps = {
  initialiseApollo: () => void;
  reloadPage: () => void;
};

export default function Routes({ 
  initialiseApollo, 
  reloadPage 
}: RoutesProps) {
  const auth = useAuth();

  /* Lazy loaded modules */
  const Dashboard = loadable(() => import(/* webpackChunkName: "assetMonitoring" */ "modules/dashboard/Dashboard"));
  const DashboardOverview = loadable(() => import(/* webpackChunkName: "assetMonitoring" */ "modules/dashboard/sub-routes/DashboardOverview"));
  const AssetMonitoring = loadable(() => import(/* webpackChunkName: "assetMonitoring" */ "modules/asset-monitoring/AssetMonitoring"));
  const ChargerStatusMessages = loadable(() => import(/* webpackChunkName: "assetMonitoring" */ "modules/asset-monitoring/sub-routes/ChargerStatusMessages"));
  const Admin = loadable(() => import(/* webpackChunkName: "assetMonitoring" */ "modules/asset-monitoring/sub-routes/Admin"));
  const Vehicles = loadable(() => import(/* webpackChunkName: "assetMonitoring" */ "modules/asset-monitoring/sub-routes/Vehicles"));
  const AddVid = loadable(() => import(/* webpackChunkName: "assetMonitoring" */ "modules/forms/add-vid/AddVid"));
  const Vehicle = loadable(() => import(/* webpackChunkName: "assetMonitoring" */ "modules/asset-monitoring/sub-routes/Vehicle"));
  
  const EnergyManagement = loadable(() => import(/* webpackChunkName: "energyManagement" */ "modules/energy-management/EnergyManagement"));
  const EnergyManagementOverview = loadable(() => import(/* webpackChunkName: "energyManagement" */ "modules/energy-management/sub-routes/EnergyManagementOverview"));
  const SiteSettings = loadable(() => import(/* webpackChunkName: "energyManagement" */ "modules/asset-monitoring/sub-routes/SiteSettings"));

  const Driver = loadable(() => import(/* webpackChunkName: "driver" */ "modules/driver/Driver"));

  const Chargers = loadable(() => import(/* webpackChunkName: "chargers" */ "components/Chargers"));
  const Charger = loadable(() => import(/* webpackChunkName: "chargers" */ "components/Charger"));
  const Adoption = loadable(() => import(/* webpackChunkName: "chargers" */ "modules/forms/adoption/Adoption"));
  const WifiSetup = loadable(() => import(/* webpackChunkName: "chargers" */ "modules/forms/wifi-setup/WifiSetup"));

  const Users = loadable(() => import(/* webpackChunkName: "users" */ "modules/users/Users"));
  const User = loadable(() => import(/* webpackChunkName: "users" */ "modules/users/User"));
  const AddUser = loadable(() => import(/* webpackChunkName: "users" */ "modules/forms/add-user/AddUser"));

  const Billing = loadable(() => import(/* webpackChunkName: "billing" */ "modules/billing/Billing"));
  const BillingDashboard = loadable(() => import(/* webpackChunkName: "billing" */ "modules/billing/sub-routes/BillingDashboard"));
  const BillingChargers = loadable(() => import(/* webpackChunkName: "billing" */ "modules/billing/sub-routes/BillingChargers"));

  const Reports = loadable(() => import(/* webpackChunkName: "reports" */ "modules/reports/Reports"));
  const SiteReports = loadable(() => import(/* webpackChunkName: "reports" */ "modules/reports/sub-routes/SiteReports"));
  const EnergyConsumption = loadable(() => import(/* webpackChunkName: "reports" */ "modules/reports/sub-routes/EnergyConsumption"));
  const CarbonSavings = loadable(() => import(/* webpackChunkName: "reports" */ "modules/reports/sub-routes/CarbonSavings"));
  // const TimeOfUse = loadable(() => import(/* webpackChunkName: "reports" */ "modules/reports/sub-routes/TimeOfUse"));
  // const AssetUptime = loadable(() => import(/* webpackChunkName: "reports" */ "modules/reports/sub-routes/AssetUptime"));
  // const Utilisation = loadable(() => import(/* webpackChunkName: "reports" */ "modules/reports/sub-routes/Utilisation"));

  const AppSettings = loadable(() => import(/* webpackChunkName: "settings" */ "modules/app-settings/AppSettings"));
  const Photos = loadable(() => import(/* webpackChunkName: "settings" */ "components/Charger/sub-components/Photos"));

  const Login = loadable(() => import(/* webpackChunkName: "public" */ "modules/public/Login"));
  const Register = loadable(() => import(/* webpackChunkName: "public" */ "modules/public/Register"));
  const CheckChargerStatus = loadable(() => import(/* webpackChunkName: "public" */ "modules/public/CheckChargerStatus"));
  const ForgotPassword = loadable(() => import(/* webpackChunkName: "public" */ "modules/public/ForgotPassword"));
  const ResetPassword = loadable(() => import(/* webpackChunkName: "public" */ "modules/public/ResetPassword"));
  const NotFound = loadable(() => import(/* webpackChunkName: "public" */ "modules/public/NotFound"));
  // const ServiceUnavailable = loadable(() => import(/* webpackChunkName: "public" */ "modules/public/ServiceUnavailable"));
  
  const Install = loadable(() => import(/* webpackChunkName: "install" */ "modules/forms/install-charger/InstallCharger"));
  const Example = loadable(() => import(/* webpackChunkName: "install" */ "modules/forms/test-form/Example"));

  /* User role setup */
  const isAssetManager = auth?.user?.salesforce_role === "Fleet_Manager";
  const isInstaller = auth?.user?.salesforce_role === "Installer";
  const isDriver = auth?.user?.salesforce_role === "Driver";
  const isEvosAdmin = (auth?.user?.email?.includes("@evos.com.au") ?? false) || (auth?.user?.roles?.includes("EvosTester") ?? false);
  const billingEnabled = isEvosAdmin;

  return (
    <RRDRoutes>

      <Route
        path="/example"
        element={<Example />}
      />
      <Route
        path="/login"
        element={
          <PublicRoute reloadPage={reloadPage}>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/register"
        element={
          <PublicRoute reloadPage={reloadPage}>
            <Register />
          </PublicRoute>
        }
      />
      <Route
        path="/forgot"
        element={
          <PublicRoute reloadPage={reloadPage}>
            <ForgotPassword />
          </PublicRoute>
        }
      />
      <Route
        path="/reset"
        element={
          <PublicRoute reloadPage={reloadPage}>
            <ResetPassword />
          </PublicRoute>
        }
      />
      <Route
        path="/check-charger-status"
        element={<CheckChargerStatus />}
      />
      <Route
        path="/authorization"
        element={<Authorization initialiseApollo={initialiseApollo} />}
      />

      { isDriver
        && <Route
          path="/driver"
          element={
            <RequireAuth>
              <Driver />
            </RequireAuth>
          }
        >
          <Route
            index
            element={
              <Chargers fallback={<Loading />} />
            }
          />
          <Route
            path="chargers"
            element={
              <Chargers fallback={<Loading />} />
            }
          />
          <Route
            path="c/:serial"
            element={
              <Charger />
            }
          />
        </Route>
      }
      { isInstaller
        && <Route
          path="/install"
          element={
            <RequireAuth>
              <Install />
            </RequireAuth>
          }
        />
      }
      { isAssetManager
        && <Route
          path="/photos/:serial"
          element={
            <RequireAuth>
              <Photos fallback={<Loading />} />
            </RequireAuth>
          }
        />
      }
      { isAssetManager
        && <Route
          path="/dashboard"
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        >
          <Route
            index
            element={
              <DashboardOverview />
            }
          />
        </Route>
      }
      { isAssetManager
        && <Route
          path="/asset-monitoring"
          element={
            <RequireAuth>
              <AssetMonitoring fallback={<Loading />} />
            </RequireAuth>
          }
        >
          <Route
            index
            element={
              <Chargers fallback={<Loading />} />
            }
          />
          <Route
            path="chargers"
            element={
              <Chargers fallback={<Loading />} />
            }
          />
          { isEvosAdmin
            && <Route
              path="vehicles"
              element={
                <Vehicles fallback={<Loading />} />
              }
            />
          }
          { isEvosAdmin
            && <Route
              path="vehicle/:vin"
              element={
                <Vehicle fallback={<Loading />} />
              }
            />
          }
          <Route
            path="status-messages"
            element={
              <ChargerStatusMessages fallback={<Loading />}/>
            }
          />
          <Route
            path="c/:serial"
            element={
              <Charger />
            }
          />
          { isEvosAdmin
            && <Route
              path="users"
            >
              <Route
                index
                element={
                  <Users fallback={<Loading />}/>
                }
              />
              <Route
                path="add"
                element={
                  <AddUser fallback={<Loading />}/>
                }
              />
              <Route
                path=":userid"
                element={
                  <User fallback={<Loading />} /> 
                }
              />
            </Route>
          }
          { isEvosAdmin
            && <Route
              path="admin"
              element={
                <Admin />
              }
            />
          }
          <Route
            path="site-settings"
            element={
              <SiteSettings />
            }
          />
        </Route>
      }
      { isAssetManager 
        && <Route
          path="/energy-management"
          element={
            <RequireAuth>
              <EnergyManagement fallback={<Loading />}/>
            </RequireAuth>
          }
        >
          <Route
            index
            element={
              <EnergyManagementOverview fallback={<Loading />} />
            }
          />
        </Route>
      }
      { isAssetManager
        && <Route
          path="/billing"
          element={
            <RequireAuth>
              <Billing fallback={<Loading />} />
            </RequireAuth>
          }
        >
          <Route
            index
            element={
              <BillingDashboard fallback={<Loading />} />
            }
          />
          {billingEnabled
            && <Route
              path="chargers"
              element={
                <BillingChargers fallback={<Loading />} />
              }
            />
          }
        </Route>
      }
      { isAssetManager
        && <Route
          path="/reports"
          element={
            <RequireAuth>
              <Reports fallback={<Loading />}/>
            </RequireAuth>
          }
        >
          <Route
            index
            element={
              <SiteReports fallback={<Loading />} />
            }
          />
          <Route
            path="site-reports"
            element={
              <SiteReports fallback={<Loading />} />
            }
          />
          <Route
            path="energy-consumption"
            element={
              <EnergyConsumption />
            }
          />
          <Route
            path="carbon-savings"
            element={
              <CarbonSavings />
            }
          />
          {/* <Route
            path="time-of-use"
            element={
              <TimeOfUse />
            }
          /> */}
          {/* <Route
            path="asset-uptime"
            element={
              <AssetUptime />
            }
          /> */}
          {/* <Route
            path="utilisation"
            element={
              <Utilisation />
            }
          /> */}
        </Route>
      }
      <Route
        path="/settings"
        element={
          <RequireAuth>
            <AppSettings />
          </RequireAuth>
        }
      />
      <Route
        path="/adoption"
        element={
          <RequireAuth>
            <Adoption />
          </RequireAuth>
        }
      />
      <Route
        path="/wifi-setup"
        element={
          <RequireAuth>
            <WifiSetup />
          </RequireAuth>
        }
      />
      { isEvosAdmin
        && <Route
          path="/add-vid"
          element={
            <RequireAuth>
              <AddVid />
            </RequireAuth>
          }
        />
      }
      <Route
        path="/"
        element={
          <RequireAuth>
            {
              isInstaller
                ? <Install />
                : isAssetManager
                  ? <Dashboard fallback={<Loading />} />
                  : <Driver fallback={<Loading />} />
            }
          </RequireAuth>
        }
      >
        { isAssetManager
          ? <Route
            index
            element={
              <RequireAuth>
                <DashboardOverview fallback={<Loading />} />
              </RequireAuth>
            }
          />
          : <Route
            index
            element={
              <RequireAuth>
                <Chargers fallback={<Loading />} />
              </RequireAuth>
            }
          />
        }
      </Route>
      <Route
        path="/c/:serial"
        element={
          <RequireAuth>
            <RedirectSerial />
          </RequireAuth>
        }
      />
      <Route
        path="/*"
        element={
          <RequireAuth>
            <NotFound />
          </RequireAuth>
        }
      />
    </RRDRoutes>
  );
}