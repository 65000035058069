import LoadingSpinner from "../LoadingSpinner";

export type ButtonProps = {
  onClick?: () => void;
  isLoading?: boolean;
  loadingText?: string;
  variant?: "primary" | "secondary" | "success" | "danger" | "warning" | "link" | "outline-primary" | "outline-secondary" | "outline-success" | "outline-danger" | "outline-warning";
}

import "./button.css";

export default function Button({
  onClick,
  isLoading = false,
  loadingText,
  variant = "primary",
  className,
  disabled,
  type,
  ...buttonProps
}: ButtonProps 
  & React.ComponentProps<"button"> 
  & Pick<React.ComponentProps<"a">, "href" | "target" | "rel">
  & Pick<React.ComponentProps<"label">, "htmlFor">
) {
  return (
    buttonProps.href != null
      ? <a
        href={buttonProps.href}
        target={buttonProps.target}
        rel={buttonProps.rel}
        className={`evos-btn evos-btn-${variant} ${className ?? ""} ${disabled ?? isLoading ?? false ? "disabled" : ""}`}
        role="button"
        onClick={onClick} 
      >
        {buttonProps.children}
      </a>
      : buttonProps.htmlFor != null
        ? <label 
          htmlFor={buttonProps.htmlFor}
          className={`evos-btn evos-btn-${variant} ${className ?? ""}`}
          onClick={onClick}
        >
          {buttonProps.children}
        </label>
        : <button 
          {...buttonProps}
          type={type ?? "button"}
          className={`evos-btn evos-btn-${variant} ${className ?? ""} fw-medium`}
          onClick={onClick} 
          disabled={disabled === true || isLoading === true} 
        >
          {isLoading 
            ? <>
              <LoadingSpinner variant={variant === "outline-secondary" ? "dark" : "light"} /> 
              {loadingText != null
                && <span>{loadingText}</span>
              }
            </>
            : buttonProps.children
          }
        </button>
  );
}
