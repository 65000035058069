import { createContext } from "react";
import type { Dispatch, SetStateAction } from "react";

interface AuthProps {
  user: {
    display_name: string,
    email: string,
    user_id: string,
    salesforce_role: string,
    roles: string[],
  } | null;
  signin: (newUser: AuthProps["user"], callback: () => void) => void;
  signout: (callback: () => void) => void;
  expiredTokenError: boolean,
  setExpiredTokenError: Dispatch<SetStateAction<boolean>>
  darkMode: boolean;
  setDarkMode: Dispatch<SetStateAction<boolean>>
  subscriptionsOffline: boolean
  setSubscriptionsOffline: Dispatch<SetStateAction<boolean>>
}

const AuthContext = createContext<AuthProps | null>(null);

const authProvider = {
  isAuthenticated: false,
  signin(callback: () => void) {
    authProvider.isAuthenticated = true;
    callback();
  },
  signout(callback: () => void) {
    authProvider.isAuthenticated = false;
    callback();
  }
};

export { AuthContext, authProvider };
export type { AuthProps };