import { ReactNode, useState } from "react";

export interface ModalOptions {
  content: ReactNode | string;
  title?: string;
  confirmButtonText?: ReactNode | string;
  confirmButtonLoading?: boolean;
  confirmButtonVariant?: "primary" | "secondary" | "success" | "danger" | "warning" | "link";
  cancelButtonText?: string;
  size?: "sm" | "md" | "lg";
  enableCloseButton?: boolean;
  enableCloseOnBackdrop?: boolean;
  handleCancel?: () => void;
  handleConfirm?: () => void;
  handleHide?: () => void;
}

interface UseModalReturnTypes {
  showModal: boolean;
  handleModal: (content?: ModalOptions) => void;
  modalOptions: ModalOptions
}

export default (): UseModalReturnTypes => {
  const [showModal, setShowModal] = useState(false);
  const [modalOptions, setModalOptions] = useState<ModalOptions>({
    content: "Modal Content",
    title: "Modal Title",
    confirmButtonText: "Submit",
    confirmButtonLoading: false,
    confirmButtonVariant: "primary",
    cancelButtonText: "Cancel",
    size: "md",
    enableCloseButton: true,
    enableCloseOnBackdrop: true,
    handleCancel: () => {},
    handleConfirm: () => {},
    handleHide: () => {},
  });

  const handleModal = (content?: ModalOptions) => {
    setShowModal(!showModal);
    if (content != null) {
      setModalOptions(content);
    }
  };

  return { showModal, handleModal, modalOptions };
};
