import { useState } from "react";

import ThemeProvider from "react-bootstrap/ThemeProvider";
import { IconContext } from "react-icons";

import { authProvider } from "lib/context/auth";
import { AuthContext } from "lib/context/auth";
import { FilterContext } from "lib/context/filter";
import { ModalProvider } from "@evos/components";
import type { AuthProps } from "lib/context/auth";

interface Children {
  children: React.ReactNode
}

function AuthProvider({ children }: Children ) {
  const [ user, setUser ] = useState<AuthProps["user"] | null>(null);
  const [ expiredTokenError, setExpiredTokenError ] = useState<boolean>(false);
  const [ darkMode, setDarkMode ] = useState<boolean>(false);
  const [ subscriptionsOffline, setSubscriptionsOffline ] = useState<boolean>(navigator.onLine ? false : true);
  const signin = (newUser: AuthProps["user"], callback: () => void) => {
    return authProvider.signin(() => {
      setUser(newUser);
      callback();
    });
  };
  const signout = (callback: () => void) => {
    return authProvider.signout(() => {
      setUser(null);
      callback();
    });
  };
  const value = { 
    user, 
    signin, 
    signout, 
    expiredTokenError, 
    setExpiredTokenError, 
    darkMode, 
    setDarkMode, 
    subscriptionsOffline,
    setSubscriptionsOffline
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function FilterProvider({ children }: Children) {
  const [filterSearchParamsString, setFilterSearchParamsString] = useState<string>(
    window.location.search.length > 0 && !window.location.pathname.includes("/c/")
      ? window.location.search.slice(1) 
      : "installation=Installed"
  );

  const value = { 
    filterSearchParamsString,
    setFilterSearchParamsString
  };

  return (
    <FilterContext.Provider value={value}>
      {children}
    </FilterContext.Provider>
  );
}

export default function Providers({ children }: Children) {
  return (
    <AuthProvider>
      <FilterProvider>
        <ThemeProvider
          breakpoints={["six-xl", "five-xl", "four-xl", "xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xs"]}
          minBreakpoint="xs"
        >
          <IconContext.Provider value={{ style: { verticalAlign: "text-bottom", marginBottom: "1px" } }}>
            <ModalProvider>
              {children}
            </ModalProvider>
          </IconContext.Provider>
        </ThemeProvider>
      </FilterProvider>
    </AuthProvider>
  );
}