import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {
  CloudPlatform1 as CloudIcon
} from "@evos/evos-react-icons";

import "./loading.css";

export default function Loading() {

  return (
    <div id="Loading">
      <Container fluid style={{position: "relative"}}>
        <Row>
          <Col className="d-flex justify-content-center">
            <CloudIcon 
              data-testid="loading-icon"
              className="loading-icon" 
              fontSize="10em"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}